import { toRaw } from 'vue';
import { AppLogger } from '~/lib/utils';
import { debounce } from 'lodash-es';
import { TRACKING_EVENTS } from '~/lib/constants';

type TrackingEvent = keyof typeof TRACKING_EVENTS | (typeof TRACKING_EVENTS[keyof typeof TRACKING_EVENTS] & string) | string;

export function useGTM () {
  const triggerEvent = debounce((event: TrackingEvent, payload: Record<any, any> | any = {}) => {
    const dataLayerData = {
      event,
      payload: toRaw(payload),
    };

    AppLogger('[dataLayer]', dataLayerData);
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(dataLayerData);

      // GTM
      window.gtag && window.gtag('event', dataLayerData.event, dataLayerData.payload);
    }
  }, 90);

  const triggerConversion = (adwordsId?: string, conversionId?: string) => {
    if (!adwordsId || !conversionId) return;
    window.gtag && window.gtag('event', 'conversion', {
      send_to: `${adwordsId}/${conversionId}`,
      event_callback: () => {},
    });
  };

  return {
    triggerEvent,
    triggerConversion,
  };
}
