import type { LocaleObject } from '@nuxtjs/i18n';

export const PHONE_REGEX = /^(?=(.*\d){10,})([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/;
export const DNI_NIE_REGEX = /^(\d{8}([A-Z]|[a-z])|^[XYZ]\d{7}([A-Z]|[a-z]))$/;
export const ALPHANUMERIC_REGEX = /^[a-zA-Z0-9áéíóúÁÉÍÓÚüÜ\s]+$/;
export const DEFAULT_LOCALES = [
  {
    code: 'en',
    iso: 'en',
    name: 'English (UK)',
    file: 'en.json',
  },
  {
    code: 'es',
    iso: 'es',
    name: 'Español (ES)',
    file: 'es.json',
  },
  {
    code: 'pt',
    iso: 'pt',
    name: 'Portugese (pt)',
    file: 'pt.json',
  },
] as LocaleObject[];

export const DEFAULT_IGNORED_ROUTES = [
  '/customer',
  // '/preview',
  '/subscription/customization',
  '/subscription/success',
  '/subscription/driver-details',
];

export const IGNORED_ROUTES_WITH_PREFIX = DEFAULT_LOCALES.reduce((result: string[], locale) => {
  const prefixedRoutes = DEFAULT_IGNORED_ROUTES.map(route => `/${locale.code}${route}`);
  return result.concat(prefixedRoutes);
}, []);

export const TRACKING_EVENTS = {
  CLICK_CTA_BANNER: 'click_CTA_banner',
  CLICK_POPULAR_VEHICLE: 'click_popular_vehicle',
  CLICK_NAVBAR_LINK: 'click_navbar_link',
  CLICK_NAVBAR_CTA: 'click_navbar_CTA',
  CLICK_HERO_CTA: 'click_hero_CTA',
  CLICK_CONTINUE_FORM_SUBSCRIPTION: 'click_continueForm_subscription',
  CLICK_VEHICLE_LIST: 'click_vehicleList',
  FORM_SUBSCRIPTION_SUBMIT: 'form_subscriptions_submit',
  CLICK_WHATSAPP: 'click_whatsapp',
} as const;
