import { defineNuxtPlugin } from '#app';

export default defineNuxtPlugin((nuxtApp) => {
  const getPath = (imgName: string): string => {
    const { S3_BUCKET_DOMAIN } = nuxtApp.$config.app;
    return `https://${S3_BUCKET_DOMAIN}/images/${imgName}`;
  };

  return {
    provide: {
      externalImage: getPath,
    },
  };
});
