import type { NavbarItem, PriceTuple } from '~/types';

export const groupBy = <T>(array: T[], predicate: (value: T, index: number, array: T[]) => string) =>
  array && array.reduce((acc, value, index, array) => {
    (acc[predicate(value, index, array)] ||= []).push(value);
    return acc;
  }, {} as { [key: string]: T[] });

export const getValueByPath = (path: string, obj: Record<string, any> | any) => {
  if (typeof obj === 'object' && obj !== null) {
    return path.split('.').reduce((a, b) => a[b], obj) as unknown as string | number;
  }

  return obj;
};

export const dateFormat = (date: Date, options: Intl.DateTimeFormatOptions = {
  dateStyle: 'medium',
  timeStyle: 'short',
  timeZone: 'Europe/Madrid',
}): string => {
  if (!date) return '';

  try {
    return new Intl.DateTimeFormat('es-ES', options).format(date);
  } catch (error) {
    console.warn(`[dateFormat] invalid date: ${date}`, error);
    return '-';
  }
};

export const countryCodeToUnicodeFlag = (locale: string) => {
  return [...locale]
    .map((letter) => {
      const code = letter.codePointAt(0);
      return code ? (code % 32) + 0x1_f1_e5 : undefined;
    })
    .filter(v => !!v)
    .map((n) => String.fromCodePoint(n as number))
    .join('');
};

export const getCountryByIsoCode = (isoCode: string, currentLang?: string) => {
  if (typeof window !== 'undefined') {
    const { DEFAULT_LOCALE } = useRuntimeConfig().public;
    const lang = currentLang || navigator.language || DEFAULT_LOCALE;

    try {
      const displayNames = new Intl.DisplayNames([lang], { type: 'region', style: 'long' });

      return displayNames.of(isoCode);
    } catch (error: any) {
      console.error(`Error: ${error.message}`);
    }
  }
};

export const formatCurrency = (amount: number, currencyCode = 'EUR', locale = 'es-ES') => {
  try {
    const decimalCount = (amount % 1 !== 0) ? 2 : 0;
    const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: decimalCount,
      maximumFractionDigits: decimalCount,
    });

    return formatter.format(amount);
  } catch (error) {
    console.error('Error formatting currency:', error);
    return '';
  }
};

export const priceFormatter = (item: PriceTuple, periodicity = 'month') => {
  return (formatCurrency(item?.price.value || 0) + '/' + periodicity);
};

export const sanitizeQueryParams = (q: Record<string, any>, whiteList: string[]) => {
  const result = structuredClone(q);
  const keys = Object.keys(result);

  keys.forEach((param) => {
    if (!whiteList.includes(param)) {
      delete result[param];
    }
  });

  return result;
};

export const serializeQueryParams = (queryParams: Record<string, any>) => {
  const params = Object.keys(queryParams)
    .map((key) => `${key}=${queryParams[key]}`)
    .join('&');

  return `${params ? `?${params}` : ''}`;
};

export const removeTrailingSlash = (path: string) => {
  const resultPath = path;
  if (resultPath.endsWith('/')) {
    return resultPath.slice(0, -1);
  }

  return resultPath;
};

export const removeLocalePrefixFromUrl = (path: string, locale = 'es') => {
  if (path === `/${locale}`) return path;
  return path.replace(new RegExp(`^\\/${locale}`), '');
};

export const getSanitizedNavbarRoutes = (navbar: NavbarItem[], isLoginEnabled: boolean) => {
  const localeRoute = useLocaleRoute();
  const sanitizedData = (navbar?.length > 0) ? navbar?.filter(navItem => !(!isLoginEnabled && navItem.value.link === '/login')) : [];
  const sanitizedRoutes = sanitizedData.map(item => {
    const rawUrlPath = item?.value?.page?.url_path || '';
    const urlPath = localeRoute(rawUrlPath);

    return {
      ...item,
      value: {
        ...item.value,
        page: {
          ...item.value.page,
          url_path: urlPath?.path,
          slugs: urlPath?.params?.slug as string[],
        },
      },
    };
  });

  return sanitizedRoutes as NavbarItem[];
};

export const AppLogger = (message: string, ...args: any[]) => {
  if (process.env.NODE_ENV === 'development' || (process.client && window.localStorage.getItem('debug-on'))) {
    // eslint-disable-next-line no-console
    console.log(`[AppLogger]-> ${message}`, ...args);
  }
};
