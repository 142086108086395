import { default as _91_46_46_46slug_93or9LyeO6gbMeta } from "/Users/jnieto/Projects/web-reborn/nuxt3-reborn_copy/pages/[...slug].vue?macro=true";
import { default as _91slug_93uTv0j0myWYMeta } from "/Users/jnieto/Projects/web-reborn/nuxt3-reborn_copy/pages/car/[slug].vue?macro=true";
import { default as indexA9Eo1p4KOqMeta } from "/Users/jnieto/Projects/web-reborn/nuxt3-reborn_copy/pages/preview/index.vue?macro=true";
import { default as catalogDnVeCVBmqiMeta } from "/Users/jnieto/Projects/web-reborn/nuxt3-reborn_copy/pages/subscription/catalog.vue?macro=true";
import { default as customizationgzEZS6dXzjMeta } from "/Users/jnieto/Projects/web-reborn/nuxt3-reborn_copy/pages/subscription/customization.vue?macro=true";
import { default as driver_45detailsdzazH8F1uPMeta } from "/Users/jnieto/Projects/web-reborn/nuxt3-reborn_copy/pages/subscription/driver-details.vue?macro=true";
import { default as successcP9MGiMCuPMeta } from "/Users/jnieto/Projects/web-reborn/nuxt3-reborn_copy/pages/subscription/success.vue?macro=true";
import { default as thank_45youminnaYdCi7Meta } from "/Users/jnieto/Projects/web-reborn/nuxt3-reborn_copy/pages/thank-you.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93or9LyeO6gbMeta?.name ?? "slug___en",
    path: _91_46_46_46slug_93or9LyeO6gbMeta?.path ?? "/en/:slug(.*)*",
    meta: _91_46_46_46slug_93or9LyeO6gbMeta || {},
    alias: _91_46_46_46slug_93or9LyeO6gbMeta?.alias || [],
    redirect: _91_46_46_46slug_93or9LyeO6gbMeta?.redirect,
    component: () => import("/Users/jnieto/Projects/web-reborn/nuxt3-reborn_copy/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93or9LyeO6gbMeta?.name ?? "slug___es___default",
    path: _91_46_46_46slug_93or9LyeO6gbMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93or9LyeO6gbMeta || {},
    alias: _91_46_46_46slug_93or9LyeO6gbMeta?.alias || [],
    redirect: _91_46_46_46slug_93or9LyeO6gbMeta?.redirect,
    component: () => import("/Users/jnieto/Projects/web-reborn/nuxt3-reborn_copy/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93or9LyeO6gbMeta?.name ?? "slug___es",
    path: _91_46_46_46slug_93or9LyeO6gbMeta?.path ?? "/es/:slug(.*)*",
    meta: _91_46_46_46slug_93or9LyeO6gbMeta || {},
    alias: _91_46_46_46slug_93or9LyeO6gbMeta?.alias || [],
    redirect: _91_46_46_46slug_93or9LyeO6gbMeta?.redirect,
    component: () => import("/Users/jnieto/Projects/web-reborn/nuxt3-reborn_copy/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93uTv0j0myWYMeta?.name ?? "car-slug___en",
    path: _91slug_93uTv0j0myWYMeta?.path ?? "/en/car/:slug()",
    meta: _91slug_93uTv0j0myWYMeta || {},
    alias: _91slug_93uTv0j0myWYMeta?.alias || [],
    redirect: _91slug_93uTv0j0myWYMeta?.redirect,
    component: () => import("/Users/jnieto/Projects/web-reborn/nuxt3-reborn_copy/pages/car/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93uTv0j0myWYMeta?.name ?? "car-slug___es___default",
    path: _91slug_93uTv0j0myWYMeta?.path ?? "/car/:slug()",
    meta: _91slug_93uTv0j0myWYMeta || {},
    alias: _91slug_93uTv0j0myWYMeta?.alias || [],
    redirect: _91slug_93uTv0j0myWYMeta?.redirect,
    component: () => import("/Users/jnieto/Projects/web-reborn/nuxt3-reborn_copy/pages/car/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93uTv0j0myWYMeta?.name ?? "car-slug___es",
    path: _91slug_93uTv0j0myWYMeta?.path ?? "/es/car/:slug()",
    meta: _91slug_93uTv0j0myWYMeta || {},
    alias: _91slug_93uTv0j0myWYMeta?.alias || [],
    redirect: _91slug_93uTv0j0myWYMeta?.redirect,
    component: () => import("/Users/jnieto/Projects/web-reborn/nuxt3-reborn_copy/pages/car/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexA9Eo1p4KOqMeta?.name ?? "preview___en",
    path: indexA9Eo1p4KOqMeta?.path ?? "/en/preview",
    meta: indexA9Eo1p4KOqMeta || {},
    alias: indexA9Eo1p4KOqMeta?.alias || [],
    redirect: indexA9Eo1p4KOqMeta?.redirect,
    component: () => import("/Users/jnieto/Projects/web-reborn/nuxt3-reborn_copy/pages/preview/index.vue").then(m => m.default || m)
  },
  {
    name: indexA9Eo1p4KOqMeta?.name ?? "preview___es___default",
    path: indexA9Eo1p4KOqMeta?.path ?? "/preview",
    meta: indexA9Eo1p4KOqMeta || {},
    alias: indexA9Eo1p4KOqMeta?.alias || [],
    redirect: indexA9Eo1p4KOqMeta?.redirect,
    component: () => import("/Users/jnieto/Projects/web-reborn/nuxt3-reborn_copy/pages/preview/index.vue").then(m => m.default || m)
  },
  {
    name: indexA9Eo1p4KOqMeta?.name ?? "preview___es",
    path: indexA9Eo1p4KOqMeta?.path ?? "/es/preview",
    meta: indexA9Eo1p4KOqMeta || {},
    alias: indexA9Eo1p4KOqMeta?.alias || [],
    redirect: indexA9Eo1p4KOqMeta?.redirect,
    component: () => import("/Users/jnieto/Projects/web-reborn/nuxt3-reborn_copy/pages/preview/index.vue").then(m => m.default || m)
  },
  {
    name: catalogDnVeCVBmqiMeta?.name ?? "subscription-catalog___en",
    path: catalogDnVeCVBmqiMeta?.path ?? "/en/subscription/catalog",
    meta: catalogDnVeCVBmqiMeta || {},
    alias: catalogDnVeCVBmqiMeta?.alias || [],
    redirect: catalogDnVeCVBmqiMeta?.redirect,
    component: () => import("/Users/jnieto/Projects/web-reborn/nuxt3-reborn_copy/pages/subscription/catalog.vue").then(m => m.default || m)
  },
  {
    name: catalogDnVeCVBmqiMeta?.name ?? "subscription-catalog___es___default",
    path: catalogDnVeCVBmqiMeta?.path ?? "/subscription/catalog",
    meta: catalogDnVeCVBmqiMeta || {},
    alias: catalogDnVeCVBmqiMeta?.alias || [],
    redirect: catalogDnVeCVBmqiMeta?.redirect,
    component: () => import("/Users/jnieto/Projects/web-reborn/nuxt3-reborn_copy/pages/subscription/catalog.vue").then(m => m.default || m)
  },
  {
    name: catalogDnVeCVBmqiMeta?.name ?? "subscription-catalog___es",
    path: catalogDnVeCVBmqiMeta?.path ?? "/es/subscription/catalog",
    meta: catalogDnVeCVBmqiMeta || {},
    alias: catalogDnVeCVBmqiMeta?.alias || [],
    redirect: catalogDnVeCVBmqiMeta?.redirect,
    component: () => import("/Users/jnieto/Projects/web-reborn/nuxt3-reborn_copy/pages/subscription/catalog.vue").then(m => m.default || m)
  },
  {
    name: customizationgzEZS6dXzjMeta?.name ?? "subscription-customization___en",
    path: customizationgzEZS6dXzjMeta?.path ?? "/en/subscription/customization",
    meta: customizationgzEZS6dXzjMeta || {},
    alias: customizationgzEZS6dXzjMeta?.alias || [],
    redirect: customizationgzEZS6dXzjMeta?.redirect,
    component: () => import("/Users/jnieto/Projects/web-reborn/nuxt3-reborn_copy/pages/subscription/customization.vue").then(m => m.default || m)
  },
  {
    name: customizationgzEZS6dXzjMeta?.name ?? "subscription-customization___es___default",
    path: customizationgzEZS6dXzjMeta?.path ?? "/subscription/customization",
    meta: customizationgzEZS6dXzjMeta || {},
    alias: customizationgzEZS6dXzjMeta?.alias || [],
    redirect: customizationgzEZS6dXzjMeta?.redirect,
    component: () => import("/Users/jnieto/Projects/web-reborn/nuxt3-reborn_copy/pages/subscription/customization.vue").then(m => m.default || m)
  },
  {
    name: customizationgzEZS6dXzjMeta?.name ?? "subscription-customization___es",
    path: customizationgzEZS6dXzjMeta?.path ?? "/es/subscription/customization",
    meta: customizationgzEZS6dXzjMeta || {},
    alias: customizationgzEZS6dXzjMeta?.alias || [],
    redirect: customizationgzEZS6dXzjMeta?.redirect,
    component: () => import("/Users/jnieto/Projects/web-reborn/nuxt3-reborn_copy/pages/subscription/customization.vue").then(m => m.default || m)
  },
  {
    name: driver_45detailsdzazH8F1uPMeta?.name ?? "subscription-driver-details___en",
    path: driver_45detailsdzazH8F1uPMeta?.path ?? "/en/subscription/driver-details",
    meta: driver_45detailsdzazH8F1uPMeta || {},
    alias: driver_45detailsdzazH8F1uPMeta?.alias || [],
    redirect: driver_45detailsdzazH8F1uPMeta?.redirect,
    component: () => import("/Users/jnieto/Projects/web-reborn/nuxt3-reborn_copy/pages/subscription/driver-details.vue").then(m => m.default || m)
  },
  {
    name: driver_45detailsdzazH8F1uPMeta?.name ?? "subscription-driver-details___es___default",
    path: driver_45detailsdzazH8F1uPMeta?.path ?? "/subscription/driver-details",
    meta: driver_45detailsdzazH8F1uPMeta || {},
    alias: driver_45detailsdzazH8F1uPMeta?.alias || [],
    redirect: driver_45detailsdzazH8F1uPMeta?.redirect,
    component: () => import("/Users/jnieto/Projects/web-reborn/nuxt3-reborn_copy/pages/subscription/driver-details.vue").then(m => m.default || m)
  },
  {
    name: driver_45detailsdzazH8F1uPMeta?.name ?? "subscription-driver-details___es",
    path: driver_45detailsdzazH8F1uPMeta?.path ?? "/es/subscription/driver-details",
    meta: driver_45detailsdzazH8F1uPMeta || {},
    alias: driver_45detailsdzazH8F1uPMeta?.alias || [],
    redirect: driver_45detailsdzazH8F1uPMeta?.redirect,
    component: () => import("/Users/jnieto/Projects/web-reborn/nuxt3-reborn_copy/pages/subscription/driver-details.vue").then(m => m.default || m)
  },
  {
    name: successcP9MGiMCuPMeta?.name ?? "subscription-success___en",
    path: successcP9MGiMCuPMeta?.path ?? "/en/subscription/success",
    meta: successcP9MGiMCuPMeta || {},
    alias: successcP9MGiMCuPMeta?.alias || [],
    redirect: successcP9MGiMCuPMeta?.redirect,
    component: () => import("/Users/jnieto/Projects/web-reborn/nuxt3-reborn_copy/pages/subscription/success.vue").then(m => m.default || m)
  },
  {
    name: successcP9MGiMCuPMeta?.name ?? "subscription-success___es___default",
    path: successcP9MGiMCuPMeta?.path ?? "/subscription/success",
    meta: successcP9MGiMCuPMeta || {},
    alias: successcP9MGiMCuPMeta?.alias || [],
    redirect: successcP9MGiMCuPMeta?.redirect,
    component: () => import("/Users/jnieto/Projects/web-reborn/nuxt3-reborn_copy/pages/subscription/success.vue").then(m => m.default || m)
  },
  {
    name: successcP9MGiMCuPMeta?.name ?? "subscription-success___es",
    path: successcP9MGiMCuPMeta?.path ?? "/es/subscription/success",
    meta: successcP9MGiMCuPMeta || {},
    alias: successcP9MGiMCuPMeta?.alias || [],
    redirect: successcP9MGiMCuPMeta?.redirect,
    component: () => import("/Users/jnieto/Projects/web-reborn/nuxt3-reborn_copy/pages/subscription/success.vue").then(m => m.default || m)
  },
  {
    name: thank_45youminnaYdCi7Meta?.name ?? "thank-you___en",
    path: thank_45youminnaYdCi7Meta?.path ?? "/en/thank-you",
    meta: thank_45youminnaYdCi7Meta || {},
    alias: thank_45youminnaYdCi7Meta?.alias || [],
    redirect: thank_45youminnaYdCi7Meta?.redirect,
    component: () => import("/Users/jnieto/Projects/web-reborn/nuxt3-reborn_copy/pages/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youminnaYdCi7Meta?.name ?? "thank-you___es___default",
    path: thank_45youminnaYdCi7Meta?.path ?? "/thank-you",
    meta: thank_45youminnaYdCi7Meta || {},
    alias: thank_45youminnaYdCi7Meta?.alias || [],
    redirect: thank_45youminnaYdCi7Meta?.redirect,
    component: () => import("/Users/jnieto/Projects/web-reborn/nuxt3-reborn_copy/pages/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youminnaYdCi7Meta?.name ?? "thank-you___es",
    path: thank_45youminnaYdCi7Meta?.path ?? "/es/thank-you",
    meta: thank_45youminnaYdCi7Meta || {},
    alias: thank_45youminnaYdCi7Meta?.alias || [],
    redirect: thank_45youminnaYdCi7Meta?.redirect,
    component: () => import("/Users/jnieto/Projects/web-reborn/nuxt3-reborn_copy/pages/thank-you.vue").then(m => m.default || m)
  }
]