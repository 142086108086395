<template>
  <NuxtLayout id="top-page">
    <NuxtPage />

    <ClientOnly>
      <Teleport to="#bottom-layout">
        <WhatsAppWidget
          v-if="webConfig.settings?.company_whatsapp_number"
          v-show="!$route.path.startsWith('/car/') || ($route.path.startsWith('/car/') && !isMobileScreen)"
          :phone-number="webConfig.settings.company_whatsapp_number"
          default-text="Hola! ☺️%0aEstoy interesado/a en suscribirme a uno de vuestros coches, pero me han surgido algunas dudas. ¿Podríais ayudarme a resolverlas? 😬 %0aGracias!"
          @is-mobile="v => isMobileScreen = v"
        />
      </Teleport>
    </ClientOnly>

    <ClientOnly>
      <Teleport to="#bottom-layout">
        <BaseCookieMessage
          v-if="trackingScripts?.length > 0"
          :title="webConfig.settings?.website_title"
        />
      </Teleport>
    </ClientOnly>

    <div id="bottom-layout" class="relative z-30" />
  </NuxtLayout>
</template>

<script lang="ts" setup>
import { provide, onMounted, shallowReactive, defineAsyncComponent } from 'vue';
import { isNumber } from 'lodash-es';
import packageJson from './package.json';
import { useCmsApi, useSiteThemeInject, useTrackingScripts, useMatchMedia, usePromiseHandler, type PromiseTuple, type PromiseHandler } from '~/composables';
import type { Banners, Footers, Forms, Navbars, VehiclesCatalogSettings, WebSettings } from './types';
import WhatsAppWidget from '@/components/widgets/WhatsappWidget.vue';
import { useRuntimeConfig, useLocaleHead } from '#imports';

const { DEFAULT_LOCALE } = useRuntimeConfig().public;
const BaseCookieMessage = defineAsyncComponent(() => import('@/components/BaseCookieMessage.vue'));
const { handleSettledPromises } = usePromiseHandler();
const { getSettings, getNavbar, getFooter, getBanners, getForms, getAnalyticsSettings, getVehicleCatalogSettings } = useCmsApi();
const webConfig = shallowReactive<Record<string, any>>({
  settings: {},
  navbar: [],
  footer: [],
  banners: [],
  forms: [],
  pageMapping: [],
  analytics: undefined,
  vehicleCatalogSettings: [],
});

const arrayOfPromises = [
  [getSettings(), handleWebSettings],
  [getNavbar(), (v: PromiseHandler<Navbars>) => handleItems(v, 'navbar')],
  [getFooter(), (v: PromiseHandler<Footers>) => handleItems(v, 'footer')],
  [getBanners(), (v: PromiseHandler<Banners>) => handleItems(v, 'banners')],
  [getForms(), (v: PromiseHandler<Forms>) => handleItems(v, 'forms')],
  [getVehicleCatalogSettings(), (v: PromiseHandler<VehiclesCatalogSettings>) => handleItems(v, 'vehicleCatalogSettings')],
];
handleSettledPromises(arrayOfPromises as PromiseTuple[]);

function handleWebSettings (result: PromiseHandler<{items: WebSettings[]}>) {
  const { status, value, reason } = result;
  if (status === 'rejected') {
    throw new Error('No web settings found, please create new settings in your CMS.', reason);
  }

  const items = value?.value?.items || [];
  webConfig.settings = items.at(0) as WebSettings;
};

function handleItems<K extends keyof typeof webConfig> (result: PromiseHandler<any>, key: K) {
  if (result.status === 'rejected') {
    throw new Error(result.reason);
  }
  webConfig[key] = result.value?.value?.items;
}

const isMobileScreen = useMatchMedia('(max-width: 1024px)');

provide('settings', webConfig);

const { getColorsConfig, getColorValue } = useSiteThemeInject();
onMounted(() => {
  if (document.body.querySelector('#theme-colors')) {
    return;
  }
  const convertObjectToText = (colorMap: Record<string, string>) => {
    let result = '';
    for (const [key, value] of Object.entries(colorMap)) {
      result += `${key}: ${getColorValue(key, value)}; `;
    }
    return result.trim();
  };
  const colorMap = getColorsConfig(webConfig.settings);
  const styleBlock = document.createElement('style');
  styleBlock.id = 'theme-colors';
  const content = document.createTextNode(`:root {${convertObjectToText(colorMap)}}`);
  styleBlock.appendChild(content);
  // TODO check this fix
  document.body.dataset.fontFamily = isNumber(webConfig?.settings?.web_font || 1) ? webConfig?.settings?.web_font : 1;
  document.body.prepend(styleBlock);
});

const analyticsData = await getAnalyticsSettings();
const trackingIds = analyticsData.value;
webConfig.analytics = trackingIds;

const {
  getScripts: getTrackingScripts,
  getNoScripts: getTrackingNoScripts,
} = useTrackingScripts();

const trackingScripts = getTrackingScripts({
  GTM_ID: trackingIds?.gtm_id || null,
  GADS_ID: trackingIds?.g_ads || null,
  GAN_ID: trackingIds?.ga_g_tracking_id || null,
  FB_ID: trackingIds?.facebook_pixel || null,
}) as any;

const getNoScripts = getTrackingNoScripts({
  GTM_ID: trackingIds?.gtm_id || null,
  GADS_ID: trackingIds?.g_ads || null,
  GAN_ID: trackingIds?.ga_g_tracking_id || null,
  FB_ID: trackingIds?.facebook_pixel || null,
}) as any;

const i18nHead = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true,
});

// eslint-disable-next-line no-undef
useHead({
  title: webConfig.settings?.website_title || 'WWM',
  htmlAttrs: {
    lang: i18nHead.value.htmlAttrs?.lang || DEFAULT_LOCALE,
    dir: i18nHead.value.htmlAttrs?.dir,
  },
  link: [
    ...(i18nHead.value.link || []),
  ],
  meta: [
    {
      name: 'version',
      content: packageJson.version,
    },
    ...(i18nHead.value.meta || []),
  ],
  script: [
    ...trackingScripts || [],
  ],
  noscript: [...getNoScripts || []],
});
</script>
