import { AppLogger } from '~/lib/utils';

interface ScriptNames {
  GTM_ID: string | null // tag manager
  GADS_ID: string | null // adwords
  GAN_ID: string | null // analytics
  FB_ID: string | null // analytics
}

declare global {
  interface Window {
    gtag: any
    ga: any
    dataLayer: any[]
    fbq: any
    smartlook: any
  }
}

export function useTrackingScripts () {
  const TRACKING_IDS = {} as any;
  const { cookiesEnabledIds } = useCookieControl();
  const gtagModule = useGtag();

  const getScripts = (tracking: ScriptNames) => {
    Object.assign(TRACKING_IDS, tracking);
    if (tracking.GAN_ID) {
      gtagModule.initialize(tracking.GAN_ID);
    }

    const scripts = [
      // tracking.GADS_ID && {
      //   src: `https://www.googletagmanager.com/gtag/js?id=${tracking.GAN_ID}`,
      //   async: true,
      // },
      tracking.GAN_ID && {
        children: `window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${tracking.GAN_ID}')
        `,
      },
      tracking.GTM_ID && {
        children: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;document.head.appendChild(j,f);
          })(window,document,'script','dataLayer','${tracking.GTM_ID}');
        `,
      },
      tracking.GADS_ID && {
        children: `
        window.gtag && gtag('config', '${tracking.GADS_ID}')
        `,
        body: true,
      },
      tracking.FB_ID && {
        children: `!function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
        `,
        body: true,
      },
    ].filter(Boolean);

    return process.env.NODE_ENV === 'production'
      ? scripts
      : [];
  };

  const getNoScripts = (tracking: ScriptNames) => {
    const noscripts = [
      tracking.FB_ID && {
        children: `<img
          height="1" width="1"
          style="display:none"
          src="https://www.facebook.com/tr?id=${tracking.FB_ID}&ev=PageView&noscript=1"/>
        `,
        body: true,
      },
      tracking.GTM_ID && {
        children: `<iframe src="https://www.googletagmanager.com/ns.html?id=${tracking.GTM_ID}}"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
        tagPosition: 'bodyOpen',
      },
    ].filter(Boolean);

    return process.env.NODE_ENV === 'production'
      ? noscripts
      : [];
  };

  const grantGAdsPermissions = () => {
    gtagModule.gtag('consent', 'update', {
      ad_user_data: 'granted',
      ad_personalization: 'granted',
      ad_storage: 'granted',
      analytics_storage: 'granted',
    });

    AppLogger('grant GAds permissions');
  };
  const denyGAdsPermissions = () => {
    gtagModule.gtag('consent', 'update', {
      ad_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      analytics_storage: 'denied',
    });

    AppLogger('deny GAds permissions');
  };

  const grantFBPermissions = () => {
    if (typeof window === 'undefined' || !('fbq' in window)) {
      return;
    }
    window.fbq && window.fbq('init', `${TRACKING_IDS.FB_ID}`);
    window.fbq('consent', 'grant');
    window.fbq('track', 'PageView');
  };
  const denyFBPermissions = () => {
    if (typeof window === 'undefined' || !('fbq' in window)) {
      return;
    }
    window.fbq && window.fbq('consent', 'revoke');
  };

  watch(
    () => cookiesEnabledIds.value,
    (current, previous) => {
      if (
        !previous?.includes('google-adwords') &&
          current?.includes('google-adwords')
      ) {
        grantGAdsPermissions();
      } else {
        denyGAdsPermissions();
      }

      if (
        !previous?.includes('google-analytics') &&
          current?.includes('google-analytics')
      ) {
        gtagModule.enableAnalytics(TRACKING_IDS?.GAN_ID);
        grantFBPermissions();
      } else {
        const cookieId = TRACKING_IDS?.GAN_ID?.replace?.('G-', '_ga_');
        const cookie = useCookie(cookieId);
        cookie.value = null;
        // ToDo change this in the fuckture
        gtagModule.enableAnalytics(TRACKING_IDS?.GAN_ID);
        // gtagModule.disableAnalytics(TRACKING_IDS?.GAN_ID);
        denyFBPermissions();
      }
    },
    { deep: true },
  );

  return {
    getScripts,
    getNoScripts,
  };
}
