import type { CarCard, CmsPage, EcosystemVehicle, PriceTuple } from '~/types';
import { priceFormatter } from './utils';
import { useCustomLocaleMapping } from './../composables';

export const isoDateToApiFormat = (date: string): string => {
  const { DEFAULT_LOCALE } = useRuntimeConfig().public;
  const dateObj = new Date(date);
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' } as Intl.DateTimeFormatOptions;
  const format = new Intl.DateTimeFormat(DEFAULT_LOCALE, options);
  const [{ value: day }, , { value: month }, , { value: year }] = format.formatToParts(dateObj);

  return `${year}-${month}-${day}`;
};

const buildCarPriceByMonth = (prices: PriceTuple[], months: number) => {
  let found = prices.find((price) => Number(price.months) === Number(months)) as PriceTuple;
  if (!found) {
    found = prices.at(-1) as PriceTuple;
  }

  return priceFormatter(found);
};

export const apiVehicleToCarCardType = (vehicles: EcosystemVehicle[], months: number, locale: 'es' | 'en'): CarCard[] => {
  const { t, findAndReplace } = useCustomLocaleMapping(locale || process.env.NUXT_PUBLIC_DEFAULT_LOCALE);

  return vehicles.map((vehicle) => ({
    id: vehicle.id,
    title: (vehicle?.make || '') + ' ' + (vehicle?.model || ''),
    price: findAndReplace(buildCarPriceByMonth(vehicle?.prices || [], months)),
    imageSrc: vehicle.image_src,
    href: `/car/${vehicle.id}`,
    perks: [
      findAndReplace(vehicle.engine),
       `${vehicle.seats} ${t('seat', vehicle.seats)}`,
       `${t(vehicle.transmission)}`,
    ].filter(Boolean) as string[],
    ecoLabel: vehicle.eco_label as any,
  }));
};

export const wagtailDataToBlocks = (partial: CmsPage | Record<string, any>) => {
  const meta = {
    ...partial?.meta,
    seo_image_url: partial?.seo_image_url,
    title: partial?.title,
  };
  const apiData: any[] = partial?.body || [];

  return {
    data: apiData.map((item: any) => ({
      blockType: item.type,
      data: item.value,
    })),
    meta,
    id: partial?.id,
  } as { data: any, meta: CmsPage['meta'], id: number };
};
