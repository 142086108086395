import { type Ref, toRaw } from 'vue';
import type { Analytics, Banners, BlogCMSChildPages, CmsPage, Footers, FormItem, Navbars, VehiclePerks, VehiclesCatalogSettings, WebSettings } from '~/types';
import { wagtailDataToBlocks } from '~/lib/transformers';
import { removeTrailingSlash } from '~/lib/utils';

const fetchFromCMS = async (path: string, cacheKey: string, _options?: Record<string, any>) => {
  const { CMS_API_BASE_URL: baseURL } = useRuntimeConfig().app;
  const opts = _options || {};
  opts.headers = {
    accept: 'application/json',
    ...opts.headers,
  };
  opts.redirect = 'follow';

  const payload = {
    baseURL,
    ...(opts && { ...opts }),
  };

  return await useAsyncData(
    cacheKey,
    () => $fetch(path, payload)
  ) as unknown as Promise<{ data: Ref<Record<string, any>>, error: Ref<any> }>;
};

const fetchFromCMSClientSide = async (path: string, _options?: Record<string, any>): Promise<Record<string, any>> => {
  const { CMS_API_BASE_URL: baseURL } = useRuntimeConfig().app;
  const opts = _options || {};
  opts.headers = {
    accept: 'application/json',
    ...opts.headers,
  };
  opts.redirect = 'follow';

  const payload = {
    baseURL,
    ...(opts && { ...opts }),
  };

  return await $fetch(path, payload);
};

interface GetBlocksResponse {
  data: { blockType: string, data: any }[],
  meta: Record<string, string | null | boolean> | CmsPage['meta']
  error?: Ref
  id: number
}

const parseError = (msg: unknown) => JSON.stringify(msg, null, 2);

export function useCmsApi () {
  const getBlocks = async (path: string, _options?: any): Promise<GetBlocksResponse> => {
    try {
      const { data, error } = await fetchFromCMS(removeTrailingSlash(path), 'getBlocks' + path, _options);
      const partial = toRaw(data.value) as CmsPage;

      return {
        ...wagtailDataToBlocks(partial),
        error,
      };
    } catch (error) {
      const err = '[useCmsApi->getBlocks]: ' + parseError(error);
      throw Error(err);
    }
  };

  const getChildPages = async (pageId: number) => {
    try {
      const path = `/pages/?child_of=${pageId}&type=pages.BlogPage&fields=image,introduction`;
      const { data, error } = await fetchFromCMS(removeTrailingSlash(path), `getChildPages${pageId}`);
      const partial = toRaw(data.value) as BlogCMSChildPages;

      return {
        data: partial,
        error,
      };
    } catch (error) {
      const err = '[useCmsApi->getPageChild]: ' + parseError(error);
      throw Error(err);
    }
  };

  const getSettings = async () => {
    try {
      const { data } = await fetchFromCMS('/web_settings/', 'getSettings');

      return data as Ref<WebSettings>;
    } catch (error) {
      const err = '[useCmsApi->getSettings]: ' + parseError(error);
      throw Error(err);
    }
  };

  const getNavbar = async (): Promise<Ref<Navbars> | null> => {
    try {
      const { data } = await fetchFromCMS('/navbars/', 'getNavbar');

      return data as Ref<Navbars> | null;
    } catch (error) {
      const err = '[useCmsApi->getNavbar]: ' + parseError(error);
      throw Error(err);
    }
  };

  const getFooter = async () => {
    try {
      const { data } = await fetchFromCMS('/footers/', 'getFooter');

      return data as Ref<Footers>;
    } catch (error) {
      const err = '[useCmsApi->getFooter]: ' + parseError(error);
      throw new Error(err);
    }
  };

  const getBanners = async () => {
    try {
      const { data } = await fetchFromCMS('/banners/', 'getBanners');

      return data as Ref<Banners>;
    } catch (error) {
      const err = '[useCmsApi->getBanner]: ' + parseError(error);
      throw new Error(err);
    }
  };

  const getForms = async () => {
    try {
      const { data } = await fetchFromCMS('/forms/', 'getForms');

      return data as Ref<FormItem>;
    } catch (error) {
      const err = '[useCmsApi->getForms]: ' + parseError(error);
      throw new Error(err);
    }
  };

  const getForm = async (formName: string) => {
    try {
      const { data } = await fetchFromCMS(`/forms/find/?name=${formName}`, formName);

      return data as Ref<FormItem>;
    } catch (error) {
      const err = '[useCmsApi->getForms]: ' + parseError(error);
      throw new Error(err);
    }
  };

  const getAnalyticsSettings = async (): Promise<Ref<Analytics>> => {
    try {
      const { data } = await fetchFromCMS('/analytics_settings/1/', 'getAnalytics');

      return data as Ref<Analytics>;
    } catch (error) {
      const err = '[useCmsApi->getAnalyticsSettings]: ' + parseError(error);
      console.error(err);
      return ref({} as Analytics);
    }
  };

  const getVehiclePerks = async (): Promise<Ref<VehiclePerks>> => {
    try {
      const data = await fetchFromCMSClientSide('/vehicle_details_perks/');

      return toRef(data) as Ref<VehiclePerks>;
    } catch (error) {
      const err = '[useCmsApi->getVehiclePerks]: ' + parseError(error);
      throw new Error(err);
    }
  };

  const getVehicleCatalogSettings = async () => {
    try {
      const { data } = await fetchFromCMS('/vehicles_catalog/', 'getVehicleCatalogSettings');

      return data as Ref<VehiclesCatalogSettings>;
    } catch (error) {
      const err = '[useCmsApi->getVehicleCatalogSettings]: ' + parseError(error);
      throw new Error(err);
    }
  };

  return {
    getBlocks,
    getSettings,
    getNavbar,
    getFooter,
    getBanners,
    getForms,
    getForm,
    getAnalyticsSettings,
    fetchFromCMS,
    fetchFromCMSClientSide,
    getVehiclePerks,
    getChildPages,
    getVehicleCatalogSettings,
  };
}
