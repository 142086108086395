import { toRaw } from 'vue';

const MANDATORY_RGB_COLORS = [
  '--brand-color-primary',
  '--brand-color-secondary',
  '--brand-color-tertiary',
  '--brand-color-black',
];

const convertHexToTailwindRgb = (hex: string) => {
  const tempHex = hex.replace('#', '');
  const r = parseInt(tempHex.substring(0, 2), 16);
  const g = parseInt(tempHex.substring(2, 4), 16);
  const b = parseInt(tempHex.substring(4, 6), 16);

  return `${r} ${g} ${b}`;
};
const getColorValue = (key: string, color: string) => {
  if (MANDATORY_RGB_COLORS.includes(key)) {
    return convertHexToTailwindRgb(color);
  }

  return color;
};

const getColorsConfig = (rawData: Record<string, any>) => {
  if (!rawData) {
    console.warn(`[siteTheme plugin]: rawData configuration is ${rawData}`);
    return {};
  }
  return Object.keys(rawData).reduce((acc, key) => {
    if (key.endsWith('_color')) {
      const rawName = key.replace('_color', '');
      const colorName = `--brand-color-${rawName.replace('_', '-')}`;
      const colorValue = rawData[key];
      acc[colorName] = colorValue;
    }

    return acc;
  }, {} as Record<string, string>);
};

const setSiteColors = (rawData: Record<string, string>) => {
  if (document === undefined) return;
  const root = document.querySelector(':root') as HTMLElement;
  if (root) {
    const colorMap = getColorsConfig(toRaw(rawData));
    for (const colorKey in colorMap) {
      const value = getColorValue(colorKey, colorMap[colorKey]);
      root.style.setProperty(colorKey, value);
    }
  }
};

export function useSiteThemeInject () {
  return {
    getColorsConfig,
    setSiteColors,
    getColorValue,
  };
}
