import { type Ref } from 'vue';

export type PromiseHandler<T> = {
  status: 'fulfilled' | 'rejected';
  value?: Ref<T>;
  reason?: any;
}
;
export type PromiseTuple = [Promise<any>, (result: PromiseHandler<any>) => void];

export function usePromiseHandler () {
  async function handleSettledPromises (arrayOfPromises: PromiseTuple[]): Promise<void> {
    const results = await Promise.allSettled(
      arrayOfPromises.map(([promise]) => promise)
    );

    results.forEach((result, index) => {
      const [, handler] = arrayOfPromises[index];
      handler(result);
    });
  }

  return {
    handleSettledPromises,
  };
}
