import * as Sentry from '@sentry/vue';
import packageJson from './../package.json';
import { AppLogger } from '~/lib/utils';

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter();

  const { public: { sentry, WEB_DOMAIN } } = useRuntimeConfig();

  if (!sentry?.dsn) {
    const sentryMock = {
      captureException: (e: any) => {
        AppLogger('[sentryMock] captureException:', e);
      },
    };

    return {
      provide: {
        sentry: sentryMock,
      },
    };
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    release: `web2.0@${packageJson.version}`,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],

    // Configure this whole part as you need it!

    tracesSampleRate: 0.08,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', WEB_DOMAIN],

    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,
  });

  Sentry.addBreadcrumb({
    category: 'domain',
    message: WEB_DOMAIN,
    level: 'info',
  });
  Sentry.setTag('domain', WEB_DOMAIN);

  // const sentryMock = {
  //   captureException: (e: any) => {
  //     AppLogger('[sentryMock] captureException:', e);
  //   },
  // };

  return {
    provide: {
      sentry: Sentry,
    },
  };
});
