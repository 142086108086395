import { snakeCase } from 'lodash-es';

export type LocaleType = 'es' | 'en';

export function useCustomLocaleMapping (locale: LocaleType) {
  const messages = {
    es: {
      seat: 'plaza | plazas',
      hybrid_gasoline: 'Híbrido Gasolina',
      hybrid_diesel: 'Híbrido Diesel',
      gasoline: 'Gasolina',
      gas: 'Gas',
      electric: 'Eléctrico',
      diesel: 'Diésel',
      month: 'mes',
      automatic: 'Automático',
      manual: 'Manual',
      all: '4x4',
      rear: 'Tracción trasera',
      front: 'Tracción delantera',
      small: 'Pequeño',
      medium: 'Medio',
      big: 'Grande',
      sport: 'Deportivo',
      family: 'Familiar',
    },
    en: {
      seat: 'seat | seats',
      hybrid_gasoline: 'Hybrid Gasoline',
      hybrid_diesel: 'Hybrid Diesel',
      gasoline: 'Gasoline',
      gas: 'Gas',
      electric: 'Electric',
      diesel: 'Diesel',
      month: 'month',
      automatic: 'automatic',
      manual: 'Manual',
      all: 'All-Wheel Drive',
      rear: 'Rear-Wheel Drive',
      front: 'Front-Wheel Drive',
      small: 'Small',
      medium: 'Medium',
      big: 'Big',
      sport: 'Sport',
      family: 'Family',
    },
  };

  const getTranslationKey = (term: string) => snakeCase(term);
  const t = (key: string, plural?: number) => {
    const translation = messages?.[locale]?.[key?.toLowerCase?.()] || key;

    const isPlural = plural && (plural > 1);
    if (isPlural) {
      return translation.split('|')[1];
    }

    return translation;
  };

  const findAndReplace = (term: string): string => {
    const keys = Object.keys(messages[locale]).join('|');
    const strictRegex = new RegExp('\\b(' + keys + ')\\b', 'i');
    const _term = getTranslationKey(term);
    const match = _term.match(strictRegex);
    const needle = match ? match[0] : null;

    if (needle) {
      return t(_term);
    }

    const relaxedRegex = new RegExp(keys, 'i');
    const softMatch = term.match(relaxedRegex)?.[0];
    if (softMatch) {
      return term.replace(softMatch, t(softMatch));
    }

    return term;
  };

  return {
    getTranslationKey,
    findAndReplace,
    t,
  };
}
